import router from "@cms/core/plugin/router";
import { useAuthStore, useConfigStore, useImpersonationStore } from "@verbleif/shared";

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function useLogout() {
  const authStore = useAuthStore();
  const { config } = useConfigStore();
  const impersonationStore = useImpersonationStore();

  async function logout(redirectToLogin = true) {
    if (!config.value) {
      throw new Error("Config not set");
    }

    await router.push({ name: "logout" });

    await delay(2000);

    if (redirectToLogin) {
      authStore.reset();
      impersonationStore.reset();

      if (import.meta.env.VITE_SKIP_OAUTH !== "true") {
        await router.push({ name: "login" });
      } else {
        window.location.replace(`${config.value.loginUrl}/logout`);
      }
    }
  }

  return { logout };
}
