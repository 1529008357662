<script setup lang="ts">
import type { AppConfig } from "@verbleif/shared";
import { useConfigStore } from "@verbleif/shared";
import { useField, useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const {
  visible = false,
  loading: initialLoading = false,
} = defineProps<{
  visible?: boolean
  loading?: boolean
}>();

const emit = defineEmits<{
  (e: "close"): void
}>();

const loading = ref(initialLoading);

const { info } = useToast();
const { t } = useI18n();
const configStore = useConfigStore();

const initialValues = {
  presetName: configStore.config.value?.presetName ?? null,
  apiUrl: configStore.config.value?.apiUrl ?? "",
  mercureUrl: configStore.config.value?.mercureUrl ?? "",
  cdnUrl: configStore.config.value?.cdnUrl ?? "",
  appEnv: configStore.config.value?.appEnv ?? "",
  loginUrl: configStore.config.value?.loginUrl ?? "",
  loginClientId: configStore.config.value?.loginClientId ?? "",
  loginRedirect: configStore.config.value?.loginRedirect ?? "",
};

const { resetForm, values, handleSubmit, meta } = useForm({
  name: "custom-url-dialog",
  initialValues,
});

const { value: apiUrl } = useField<string>("apiUrl");
const { value: mercureUrl } = useField<string>("mercureUrl");
const { value: cdnUrl } = useField<string>("cdnUrl");
const { value: appEnv } = useField<string>("appEnv");
const { value: loginUrl } = useField<string>("loginUrl");
const { value: loginClientId } = useField<string>("loginClientId");
const { value: loginRedirect } = useField<string>("loginRedirect");

let fromReset = false;

const onSubmit = handleSubmit((values) => {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    values.presetName = null; // Mark as custom
    configStore.setOverrideConfig(values as AppConfig);
  } finally {
    loading.value = false;
  }
});

watch(values, () => {
  if (fromReset) {
    fromReset = false;
    return;
  }

  onSubmit();
});

async function reset() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    fromReset = true;
    configStore.reset();
    resetForm({
      values: {
        presetName: configStore.config.value?.presetName ?? null,
        apiUrl: configStore.config.value?.apiUrl ?? "",
        mercureUrl: configStore.config.value?.mercureUrl ?? "",
        cdnUrl: configStore.config.value?.cdnUrl ?? "",
        appEnv: configStore.config.value?.appEnv ?? "",
        loginUrl: configStore.config.value?.loginUrl ?? "",
        loginClientId: configStore.config.value?.loginClientId ?? "",
        loginRedirect: configStore.config.value?.loginRedirect ?? "",
      },
    });
    info(t("settings.configOverride.resetSuccessful"));
  } finally {
    loading.value = false;
  }
}

function closeDialog() {
  emit("close");
}
</script>

<template>
  <VDialog
    id="custom-url-dialog"
    :visible="visible"
    size="medium"
    mask
    :is-dirty="() => meta.dirty"
    @close="closeDialog"
  >
    <template #title>
      <FormDebug>
        <div class="text-xl font-bold">
          {{ t("settings.configOverride.custom") }}
        </div>
      </FormDebug>
    </template>
    <template #content>
      <div class="flex flex-col gap-2.5 h-full overflow-y-auto px-2">
        <VInput
          v-model="apiUrl"
          :label-left="t('settings.configOverride.apiUrlLabel')"
          :placeholder="t('settings.configOverride.apiUrlPlaceholder')"
        />
        <VInput
          v-model="mercureUrl"
          :label-left="t('settings.configOverride.mercureUrlLabel')"
          :placeholder="t('settings.configOverride.mercureUrlPlaceholder')"
        />
        <VInput
          v-model="cdnUrl"
          :label-left="t('settings.configOverride.cdnUrlLabel')"
          :placeholder="t('settings.configOverride.cdnUrlPlaceholder')"
        />
        <VInput
          v-model="appEnv"
          :label-left="t('settings.configOverride.appEnvLabel')"
          :placeholder="t('settings.configOverride.appEnvPlaceholder')"
        />
        <VInput
          v-model="loginUrl"
          :label-left="t('settings.configOverride.loginUrlLabel')"
          :placeholder="t('settings.configOverride.loginUrlPlaceholder')"
        />
        <VInput
          v-model="loginClientId"
          :label-left="t('settings.configOverride.loginClientIdLabel')"
          :placeholder="t('settings.configOverride.loginClientIdPlaceholder')"
        />
        <VInput
          v-model="loginRedirect"
          :label-left="t('settings.configOverride.loginRedirectLabel')"
          :placeholder="t('settings.configOverride.loginRedirectPlaceholder')"
        />
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full">
        <VDivider class="w-full !mb-6" />
        <div class="flex flex-row w-full gap-2.5">
          <VButton
            :loading="loading"
            rounded
            class="mr-auto"
            variant="is-info"
            icon-left="chevron-left"
            @click="close"
          >
            {{ t("base.back") }}
          </VButton>
          <VButton
            :loading="loading"
            rounded
            outlined
            icon-left="undo"
            variant="is-danger"
            @click="() => reset()"
          >
            {{ t("settings.configOverride.revertToDefault") }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>
</template>

<style scoped>
.dialog {
  @apply p-4 text-[var(--text)] flex flex-col gap-2.5;
}
</style>
